.career{
    background:linear-gradient(90deg, var(--gray1) 0%, #ECE9F2 100%);
}

.career h3{
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 5px;
  color: var(--primary);
}

.career h4{
  font-weight: 500;
  font-size: 1.35em;
  margin-bottom: 10px;
  color: var(--gray2);
}

.career p{
  color: var(--gray4);
  font-size: 1.15em;
  margin: 0;
}

.dateTime{
  color: var(--gray4);
  font-size: 1.2em !important;
}

.careerBox{
  transition-duration: .3s;
  cursor: default;
}

.careerBox:hover{
  transform: scale(1.02);
}