.contact{
  background:linear-gradient(90deg, var(--gray1) 0%, #ECE9F2 100%);
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.contactForm{
    flex: 1;
    margin-bottom: 50px;
}

.copyright{
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 30px;
}

form {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .form {
    background: #0e101c;
    max-width: 100px;
    margin: 0 auto;
  }
  
  
  input, button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--pink1);
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: "MavenProRegular";
  }
  
  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-top: 10px;
    color: var(--gray4);
    font-size: 1.3em;
    font-weight: 200;
  }

  
  
  button[type="submit"] {
    background: linear-gradient(90deg, var(--pink1) 0%, var(--pink2) 100%);
    color: white;
    text-transform: uppercase;
    border: solid 1px var(--pink1);
    margin-top: 40px;
    padding: 20px;
    font-size: 1.25em;
    font-weight: 100;
    letter-spacing: 10px;
    transition-duration: .3s;
    cursor: pointer;

  }
  
  button[type="submit"]:hover {
    background: linear-gradient(90deg, var(--gray1) 0%, var(--gray1) 100%);
    color: var(--pink1);
    background: var(--gray1);
  }
  
  input[type="button"]:active,
  button[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  

  input[type="button"]:active,
  button[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  
  input:disabled {
    opacity: 0.4;
  }
  
  input[type="button"]:hover {
    transition: 0.3s all;
  }

  input::placeholder, textarea::placeholder {
    color: var(--gray2);
    font-weight: 200;
    font-family: "MavenProRegular";
  }

.message{
    width: 100%;
    min-height: 8em;
    resize: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid var(--pink1);
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 1em;
    font-family: "MavenProRegular";
}

.networks{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.networks > a{
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    background-color: #fff;
    transition-duration: .2s;
    color: var(--primary);
    cursor: pointer ;
    font-size: 2em;
}

.networks>a:nth-last-child(1){
  border-bottom-right-radius: 30px;
}

.networks>a:nth-child(1){
  border-top-left-radius: 30px;
}

.networks > a:hover{
  background-color: var(--primary);
  color: #fff;
}


.iconNetwork{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    transition-duration: .2s;
}


@media screen and (max-width: 880px) {
  .copyright{
    margin-bottom: 30px;
  }
}