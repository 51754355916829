.quoteBox {
    display: flex;
    margin: 0 15px;
    border-radius: 10px;
    background-color: var(--gray1);
    padding-top: 20px;
    flex: 1;
    transition-duration: .3s;
    cursor: default;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

.quoteBox:hover{
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.quoteB{
    display: flex;
    flex-direction: column;
    flex: 1;
}

  .quoteIconCont{
    text-align: left;
  }
  
  .quoteIcon {
    width: 50px;
    font-size: 2.2em;
    margin-left: 20px;
  }
  
  .quoteText {
    font-size: 0.8em;
    padding: 0 20px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .greenRectangle {
    width: 100%;
    position: relative;
    text-align: center;
    padding-bottom: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: 20px;
    
  }

  .quoteMiddle{
    width: 100%; 
    height: auto;
  }

  .quoteMiddle svg, .quoteMiddle > *{
    stroke: none;
    stroke-width: 0;
  }

  .quoteBottom{
    flex: 1;
  }

  .quoteImage{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: solid 3px #fff;
    margin-top: 0px;
    position: absolute;
    left: 50%;
    top: calc(50% - 63px);
    z-index: 1;
    transform: translateX(-50%);
    object-fit: cover;
    background-color: #fff;


  }

  .quoteAuthor p{
    font-size: 1.1em;
    color: #fff;
    font-weight: 400;
    margin-top: 25px;
  }


@media screen and (max-width: 880px) {
    .quoteBox{
        margin-bottom: 20px;
    }
  }