.circleCont{
    width: 60px;
    height: 60px;
    background: linear-gradient(to right, var(--pink1), var(--pink2));
    display: flex;
    justify-content: center;
    align-items: center;
    color : #fff;
    font-size: 1.8em;
    transition-duration: .2s;
    border-radius: 50%;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.15);
}

.circleCont:hover{
    transform: scale(1.05);
}