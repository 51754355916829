.testimonial{
    background-color: #fff;
}

.testimonial h1{
    color: var(--gray4);
}

.testimonialDescription{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
}

@media screen and (max-width: 880px) {
    .testimonialDescription{
        flex-direction: column;
    }
  }