.about{
    background-color: #fff;
    padding: 50px 5vmin 50px 15vmin;
    width: 100vw;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.about h1{
    margin-left: 18vmin;
    margin-bottom: 20px;
    color: var(--gray4);
}

.aboutSection{
    display: flex;
    height: 100%;
    width: 100%;
}

.leftAbout{
    flex: 2;
    line-height: 1.6em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rightAbout{
    flex: 1;
}

.iconsAbout{
    height: 80px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 80px;
}

@media screen and (max-width: 880px) {
    .about{
        padding: 20px 5vmin;
        text-align: center;
    }
    .aboutSection{
        flex-direction: column;
    }
    .about h1{
        margin-left: 0;
      }
  }