.skills{
    background:linear-gradient(90deg, var(--gray1) 0%, #ECE9F2 100%);
}

.skillsDescription{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.skillsDescription > div{
    flex: 40%;
}

.skillBox{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin: 30px 20px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
    transition-duration: .3s;
    cursor: default;
}

.skillBox:hover{
    transform: scale(1.015);
}

.skillCarts{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.skillCart{
    width: 100px;
    background-color: #fff;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.skillIconCont{
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skillIcon{
    width: 70px;
    height: auto;
}

.skillName{
    height: 24px;
    color: var(--primary);
    font-size: 1.1em;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1197px) {
    .skillsDescription{
        flex-direction: column;
    }
  }

  @media screen and (max-width: 880px) {
  }