:root{
  --primary: #8d8dbc;
  --secondary: #8472dd;
  --gray1: #F3F4F5;
  --gray2: #aaa;
  --gray3: #ddd;
  --gray4: #595959;
  --gray5: #ECE9F2;
  --blue1: #94A9D8;
  --blue2: #99a5e6;
  --blue3: #efe8f4;
  --green1: #7ccf7c;
  --pink1: #C890C0;
  --pink2: #b07eb9;
}

@font-face {
  font-family: "MavenProRegular";
  src: url("./assets/fonts/MavenPro-Regular.ttf") format("truetype");
  font-display: swap;
  }

  @font-face {
    font-family: "MavenProSemiBold";
    src: url("./assets/fonts/MavenPro-SemiBold.ttf") format("truetype");
    font-display: swap;
    }

    @font-face {
      font-family: "MavenProBold";
      src: url("./assets/fonts/MavenPro-Bold.ttf") format("truetype");
      font-display: swap;
      }

      
  @font-face {
    font-family: "RalewayRegular";
    src: url("./assets/fonts/Raleway-Regular.ttf") format("truetype");
    font-display: swap;
    }

  
html, body{
  background-color: var(--gray1);
  scroll-behavior: smooth;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: "MavenProRegular";
  -webkit-font-smoothing: antialiased;  
  width: 100%;
  overflow-x: hidden;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section{
  padding: 50px 5vmin 50px 5vmin;
  width: 100vw;
  min-height: calc(50vh - 60px);
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

h1{
  font-weight: 500;
  font-size: 2.3em;
  margin-left: 18vmin;
  margin-bottom: 20px;
  color: var(--secondary);
}

h2{
  font-size: 2em;
}

h3{
  font-weight: 500;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: var(--gray2);
}

h4{
  font-weight: 500;
  font-size: 1.35em;
  color: var(--primary);
}

p{
  color: var(--gray4);
  font-size: 1.3em;
}

.bold{
  font-weight: bold;
  color: var(--secondary);
}


@media screen and (max-width: 880px) {
  section{
      padding: 20px 5vmin;
      text-align: center;
  }
  h1{
    margin-left: 0;
  }
}