.publications{
  background-color: #fff;
}

.publications h1{
  color: var(--gray4);
}

.publishDescription{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.publishDescription > div{
  flex: 46%;
}

.publishBox:hover{
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.publishBox{
  background:linear-gradient(90deg, var(--gray1) 0%, #F3f3f3 100%);
  padding: 20px 20px 20px 20px;
  border-radius: 8px;
  margin: 30px 20px 10px 20px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition-duration: .3s;
}

.publishBox a{
  text-decoration: none;
}

.publishTop{
  display: flex;
  flex-direction: row;
}

.publishBox h4{
  flex: 1;
}

.publishDate{
  width: auto;
  margin-left: 20px;
  color: var(--gray2);
  font-size: 1em;
}

.publishAuthors{
  color: var(--gray2);
  margin: 10px 0;
  font-style: italic;
  margin-bottom: 20px;
}

.publishType{
  border-radius: 8px;
  font-size: 1.2em;
  padding: 10px 20px;
  color: #fff;
}


@media screen and (max-width: 1380px) {
  .publishBox{
      max-width: 80%;
  }
}

@media screen and (max-width: 880px) {
  .publishBox{
      max-width: 100%;
  }
  .publishTop{
    flex-direction: column;
  }
  .publishDate{
    margin-top: 5px;
  }
}
