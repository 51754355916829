.home {
  background:linear-gradient(-45deg, var(--gray1) 0%,var(--gray5) 25%, var(--blue3) 50%, var(--gray5) 75%, var(--gray1) 100%);
  background-size: 400% 400%;
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3vmin;
  color: var(--primary);
  text-shadow : 2px 2px 5px rgba(128,128,128,0.03); 
  animation: bg 15s ease forwards infinite;
}

@keyframes bg{
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}

.picMain{
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picCont{
  height: 35vmin;
  width: 35vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/assets/images/Nat5A.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 500px;
  box-shadow: 0 0 8px 0 var(--primary);
  transition-duration: .25s;
  transform: translateX(0px);
  z-index: 1;
  transition-timing-function: ease-out;
}

.hiddenText{
  position: absolute;
  opacity: 1;
  z-index: 0;
  transition-duration: .25s;
  color: var(--pink2);
  font-size: 1.3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-timing-function: ease-out;
}

.arrowRight{
  transform: rotateZ(180deg);
  font-size: 1.3em;
  margin-top: 15px;
  margin-right: 60px;
}

.home:has(.ctaHome:hover)  .picCont{
  transform: translateX(-16vmin);
}

.home:has(.ctaHome:hover)  .hiddenText{
  transform: translateX(16vmin);
}


.home  h1{
  color: var(--primary);
  font-weight: 400;
  margin: 20px 20px 10px 20px;
  text-align: left;
  font-size: 1.6em;
}

.home p{
  text-align: center;
  margin: 0;
  font-size: 1.2em;
  font-weight: 500;
  color: #aaa;
  margin-bottom: 70px;
}

    .st0{opacity:0.31;fill:none;stroke:#C3A1CC;stroke-miterlimit:10;}
    .st1{opacity:0.4086;fill:none;stroke:#C49FCA;stroke-miterlimit:10;}
    .st2{opacity:0.5071;fill:none;stroke:#C59CC9;stroke-miterlimit:10;}
    .st3{opacity:0.6057;fill:none;stroke:#C69AC7;stroke-miterlimit:10;}
    .st4{opacity:0.7043;fill:none;stroke:#C698C5;stroke-miterlimit:10;}
    .st5{opacity:0.8029;fill:none;stroke:#C795C3;stroke-miterlimit:10;}
    .st6{opacity:0.9014;fill:none;stroke:#C892C1;stroke-miterlimit:10;}
    .st7{fill:none;stroke:#C890C0;stroke-miterlimit:10;}

  @keyframes animateDash {
    from {
      stroke-dashoffset: 2300;
    }
    to{
      stroke-dashoffset: 0;
    }
  }
  

  svg, .st0,.st1,.st2,.st3,.st4,.st5,.st6,.st7{
    width: 100%;
  stroke-dasharray: 2300;
  stroke-dashoffset: 2300;
  pointer-events: none;
  animation: animateDash 1.4s cubic-bezier(0.445, 0.05, 0.55, 0.7) forwards;
  animation-iteration-count: 1;
  }

  .st7{
    animation-delay: 0s;
  }

  .st6{
    animation-delay: 0.08s;
  }

  .st5{
    animation-delay: 0.16s;
  }

  .st4{
    animation-delay: 0.24s;
  }

  .st3{
    animation-delay: 0.32s;
  }

  .st2{
    animation-delay: 0.40s;
  }
  
  .st1{
    animation-delay: 0.48s;
  }
  
  .st0{
    animation-delay: 0.56s;
  }

  svg path:nth-child(2n){
    animation-delay: .1s;
  }

  svg path:nth-child(2n+1){
    animation-delay: 0s;
  }

  .overlay{
    transition-duration: .8s;
    background-color: var(--gray1);
    position: fixed;
    left:0;
    top:0;
  }


  .ctaHome{
    color: #fff;
    background-color: var(--primary);
    padding: 15px 50px;
    border-radius: 10px;
    font-size: 1em;
    cursor: pointer;
    transition-duration: .25s;
    background: linear-gradient(90deg, var(--pink1) 0%, var(--pink2) 100%);
    border: 1px solid var(--pink1);
    box-shadow: 2px 3px 8px 0 rgba(128,128,128,0.3);
    text-decoration: none;
    z-index: 1;
  }

  

  .ctaHome:hover{
    color: var(--pink1);
    background: var(--gray1);
    transform: scale(1.05);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.3;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 0.3;
    }
    to {
      opacity: 0;
    }
  }
  
  .imageHome {
    position: absolute;
    animation-duration: 0.8s;
    width: 50px;
    opacity: 0;
    z-index: 0;
  }
  
  .fade-in {
    animation-name: fadeIn;
    animation-fill-mode: forwards;
  }
  
  .fade-out {
    animation-name: fadeOut;
    animation-fill-mode: forwards; 
  }

  @media screen and (max-width: 880px) {
    .picCont{
      margin-top: 0px;
      height: 50vmin;
      width: 50vmin;
      margin-bottom: 30px;
    }
    .home h1{
      font-size: 2em;
    }
    .home p{
      font-size: 1.4em;
    }
    .ctaHome{
      font-size: 1.3em;
    }
    .home:has(.ctaHome:hover)  .picCont{
      transform: translateX(0);
    }
    
    .home:has(.ctaHome:hover)  .hiddenText{
      transform: translateX(0);
    }
  }

  @media screen and (max-width: 680px) {
    .picCont{
      margin-top: 0px;
      height: 50vmin;
      width: 50vmin;
      margin-bottom: 30px;
    }
    .home h1{
      font-size: 2.2em;
    }
    .home p{
      font-size: 1.7em;
    }
    .ctaHome{
      font-size: 1.8em;
    }
    .home:has(.ctaHome:hover)  .picCont{
      transform: translateX(0);
    }
    
    .home:has(.ctaHome:hover)  .hiddenText{
      transform: translateX(0);
    }
  }