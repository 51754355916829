.svg-atom{
  max-height: 400px;
}

.nucleon{
  filter: drop-shadow(2px 3px 3px rgb(0 0 0 / 0.2));
}

.electron{
  filter: drop-shadow(2px 3px 3px rgb(0 0 0 / 0.1));
}