.App{
  max-width: 100%;
}

.overlay{
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upButton{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: var(--gray1);
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: .5s;
}

.chevronUp{
  font-size: 25px;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  color: var(--pink2);
}

.upButton:hover .chevronUp{
  transform: translateY(-3px);
}

@media screen and (max-width: 880px) {
  .upButton{
    bottom: 30px;
  }
}