header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

}

.header{
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    height: 60px;

}

.progress-bar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, var(--blue1), var(--secondary));
    flex: 1;
    transform-origin: 0%;
    border-radius: 0 2px 2px 0;
  }
  

.headerLeft{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leftTitle{
    font-family: "MavenProBold";
    font-size: 1.45em;
    color: var(--pink2);
    margin: 10px 25px;
    text-decoration: none;
    transition-duration: .3s;
    cursor: pointer;
}

.leftTitle:hover{
    transform: scale(1.04);
}

.headerRight{
    text-align: right;
    flex:  1;
}

.headerElem:not(:nth-last-child(1)){
    display: inline-block;
    margin: 0px 16px;
    color: #222;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.06em;
    padding: 8px 4px;
    background-image: linear-gradient(to right, var(--primary), var(--secondary));
    background-position: 0 100%;
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s,
      color 0.2s;
      cursor: pointer;
}

.headerElem:nth-child(n-1):hover{
    color: var(--primary);
    background-position: 100% 100%;
    background-size: 100% 1px;
}

.headerElem:nth-last-child(1){
    display: inline-block;
    margin: 10px 25px 10px 16px;
    color: #222;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.06em;
    padding: 8px 25px;
    color:  var(--primary);
    border: solid 1px var(--primary);
    border-radius: 8px;
    margin-right: 40px;
    background: linear-gradient(to right, var(--primary), var(--secondary));
    background-position: 0 100%;
    background-size: 0% 100%;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s,
      color 0.3s,
      border-color 0.3s;
    cursor: pointer;
}

.headerElem:nth-last-child(1):hover{
    color: #fff;
    background-position: 100% 100%;
    background-size: 100% 100%;
}
  
  .btn { 
    width: 50px;
    cursor: pointer;
    margin-left: 30px;
    display: none;
    padding: 8px;
  }
  
  .btn span {
    display: block;
    width: 100%;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.15);
    border-radius: 3px;
    height: 4px;
    background: linear-gradient(to right, var(--pink1), var(--pink2));
    transition: all .3s;
    position: relative;
  }
  
  .btn span +  span {
    margin-top: 8px;
  }
  
  .active span:nth-child(1) {
    animation: ease .7s top forwards;
  }
  
  .not-active span:nth-child(1) {
    animation: ease .7s top-2 forwards;
  }
  
  .active span:nth-child(2) {
    animation: ease .7s scaled forwards;
  }
  
  .not-active span:nth-child(2) {
    animation: ease .7s scaled-2 forwards;
  }
  
  .active span:nth-child(3) {
    animation: ease .7s bottom forwards;
  }
  
  .not-active span:nth-child(3) {
    animation: ease .7s bottom-2 forwards;
  }
  
  @keyframes top {
    0% {
      top: 0;
      transform: rotate(0);
    }
    50% {
      top: 12px;
      transform: rotate(0);
    }
    100% {
      top: 12px;
      transform: rotate(45deg);
    }
  }
  
  @keyframes top-2 {
    0% {
      top: 12px;
      transform: rotate(45deg);
    }
    50% {
      top: 12px;
      transform: rotate(0deg);
    }
    100% {
      top: 0;
      transform: rotate(0deg);
    }
  }
  
  @keyframes bottom {
    0% {
      bottom: 0;
      transform: rotate(0);
    }
    50% {
      bottom: 12px;
      transform: rotate(0);
    }
    100% {
      bottom: 12px;
      transform: rotate(135deg);
    }
  }
  
  @keyframes bottom-2 {
    0% {
      bottom: 12px;
      transform: rotate(135deg);
    }
    50% {
      bottom: 12px;
      transform: rotate(0);
    }
    100% {
      bottom: 0;
      transform: rotate(0);
    }
  }
  
  @keyframes scaled {
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes scaled-2 {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .menuBurger{
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.35);
    transition-duration: .4s;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1) ;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    border-radius: 0 0px 5px 5px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }  

  .burgerItem{
    padding: 0 20px;
    margin: 14px 0;
    font-size: 1.3em;
    cursor: pointer;
    color: #fff;
    padding-bottom: 5px;
    background-image: linear-gradient(to right, #fff, #fff);
    background-position: 0 100%;
    background-size: 0% 1px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s,
      color 0.2s;
      cursor: pointer;
    text-decoration: none;
  }

  .burgerItem:hover{
    background-position: 100% 100%;
    background-size: 100% 1px;
  }


@media screen and (max-width: 1080px) {
    .headerElem:not(:nth-last-child(1)){
        margin: 0px 12px;
        font-size: 1em;
    }

    .headerElem:nth-last-child(1){
        margin: 10px 25px 10px 16px;
        font-size: 1em;
    }
}

@media screen and (max-width: 980px) {
    .leftTitle{
        font-size: 1.2em;
    }
    .headerElem:not(:nth-last-child(1)){
        display: none;
    }
    .btn{
      display: block;
    }
    .leftTitle{
      display: none;
    }
}